<template>
  <div class="process-optimize">
    <img
      class="animate__animated"
      v-scroll="'animate__fadeIn'"
      src="@assets/images/ProcessOptimize3.png"
      alt
      width="100%"
    />
    <div class="number1">
      <img src="@assets/images/SystemBuild2.png" alt width="100%" />
      <div class="contain">
        <div class="BoxTitle animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="padding-top:0.85rem;font-weight: normal !important">服务介绍</p>
        </div>
        <p class="des animate__animated" v-scroll="'animate__fadeIn'" style="animation-delay: 1s;text-indent: 2.2em;">
          景翔研究流程管理近三十年，辅导过各行业各种规模的企业建立流程体系和流程优化，积累了丰富实战经验，并形成一套成熟的流程管理方法论。
          流程的目的是为实现业务流程，业务流程的目的是为实现企业战略目标，我们一直致力于为企业搭建围绕战略目标的流程体系，主张建立以核心业务为中心的管理模式，通过业务流程识别、展开、分解，再制定标准和责任人。通过对核心业务流程的拉通和优化，解决了传统体系与战略脱钩、流程滞后、全责不明、信息孤岛等问题。而流程优化，则在建立核心业务流程后，进行流程分解，制定流程要求，流程分析优化，梳理流程接口，对流程进一步精细化管理，为企业业务数字化奠定基础。
        </p>
        <div class="BoxTitle animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="font-weight: normal !important">流程优化的服务:</p>
        </div>
        <div class="ul">
          <span
            class="animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list1"
            :key="index"
          >{{ item }}</span>
        </div>
        <div class="ul">
          <span
            class="animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list2"
            :key="index"
            :class="{ dispalyNone: !item }"
          >{{ item }}</span>
        </div>
      </div>
    </div>

    <div class="number2">
      <div v-scroll="'animate__bounceIn'" class="BoxTitle animate__animated">
        <p>为什么要做流程优化？</p>
        <p></p>
      </div>
      <div class="box">
        <div
          class="box-item animate__animated"
          v-for="(item, index) in hexagonList"
          :key="index"
          v-scroll="'animate__fadeInDown'"
        >
          <div class="hexagon">0{{ index + 1 }}</div>
          <p>{{item.title}}</p>
        </div>
      </div>
    </div>

    <div class="number3">
      <div v-scroll="'animate__bounceIn'" class="BoxTitle animate__animated">
        <p>我司流程管理研究成果</p>
        <p></p>
      </div>
      <!-- <div class="books">
        <img src="../../../assets/images/book7.png" alt />
        <img src="../../../assets/images/book3.png" alt />
        <img src="../../../assets/images/book2.png" alt />
        <img src="../../../assets/images/book4.png" alt />
        <img src="../../../assets/images/book8.png" alt />
      </div>-->
    </div>

    <div class="number4">
      <div v-scroll="'animate__bounceIn'" class="BoxTitle animate__animated">
        <p>流程优化三步走</p>
        <p></p>
      </div>
      <img src="../../../assets//images/ProcessOptimize5.png" alt />
    </div>

    <div class="number5">
      <div v-scroll="'animate__bounceIn'" class="BoxTitle animate__animated">
        <p>流程优化分析软件</p>
        <p></p>
      </div>
      <div class="contain">
        <p class="ml36">我司独立开发出流程优化分析系统，协助企业对流程进行分解和分析，让流程缺陷一目了然，还有分类、筛选等功能，让流程统计更加方便快捷</p>
        <div class="meshing">
          <img
            src="../../../assets/images/ProcessOptimize6.png"
            alt
            class="animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
          />
          <div
            class="rightBox animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s"
          >
            <div class="title">流程文件网格化</div>
            <p class="dec">
              1.根据职能三级分解后得出的管理类文件进行活动分解。
              2.文件网格化后，除了可以对流程进行分析之用，还可以直接作为文件使用。
              <br />3.因为已经涵盖了流程、部门、负责人、过程要求等要素
            </p>
            <div class="btn" @click="$router.push('./trial-use')">免费试用</div>
          </div>
        </div>
        <div class="analyse">
          <div
            class="leftBox animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
          >
            <div class="title">流程分析软件的岗位职责统计与分析</div>
            <img class="img1" src="../../../assets/images/ProcessOptimize9.png" alt />
            <img class="img2" src="../../../assets/images/ProcessOptimize10.png" alt />
            <img class="img4" src="../../../assets/images/ProcessOptimize8.png" alt />
          </div>
          <img
            class="img3 animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s"
            src="../../../assets/images/ProcessOptimize7.png"
            alt
          />
        </div>
      </div>
      <div class="bottomBox"></div>
    </div>

    <div class="number6">
      <div v-scroll="'animate__bounceIn'" class="BoxTitle animate__animated">
        <p>流程优化相关培训</p>
        <p></p>
      </div>
      <div class="imgBox">
        <div class="diamondLeft">
          <div
            class="diamond-item animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in diamondLeft"
            :key="index"
          >
            <div class="lineBox">
              <p>{{ item.title }}</p>
              <span class="straight"></span>
              <span class="broken"></span>
            </div>
            <img :src="item.imgUrl" alt />
          </div>
        </div>
        <img src="../../../assets/images/ProcessOptimize12.png" alt />
        <span class="text">流程优化相关培训</span>
        <div class="diamondRight">
          <div
            class="diamond-item animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in diamondRight"
            :key="index"
          >
            <div class="lineBox">
              <p>{{ item.title }}</p>
              <span class="straight"></span>
              <span class="broken"></span>
            </div>
            <img :src="item.imgUrl" alt />
          </div>
        </div>
      </div>
    </div>

    <div class="number7">
      <div v-scroll="'animate__bounceIn'" class="BoxTitle animate__animated">
        <p>部分成果</p>
        <p></p>
      </div>
      <div class="tabBox">
        <div class="tab">
          <div
            class="tab-item"
            v-for="(item, index) in tabList"
            :key="index"
            @click="handleTab(index)"
          >
            <div class="tabBtn" :class="{active: index === tabIndex}">{{ item }}</div>
            <img v-show="index === tabIndex" src="../../../assets/images/ProcessOptimize21.png" alt />
          </div>
        </div>
        <div class="lineBox"></div>
        <div
          class="tabContent animate__animated"
          v-scroll="'animate__bounceIn'"
          v-for="(item, index) in tabImg"
          :key="index"
          v-show="index === tabIndex"
        >
          <img :src="item" alt :class="'img' + index" />
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import animateScroll from "../../../modules/AnimateScroll.js";
export default {
  data() {
    return {
      serveList: {
        list1: [
          "职能优化",
          "构建新型价值链",
          "构建体系树",
          "目标指标优化",
          "信息流优化",
        ],
        list2: ["流程素数字化", "流程分解", "流程优化", "体系文件优化", ""],
      },
      hexagonList: [
        {
          title: "为战略实现提供体系保证",
        },
        {
          title: "确保各业务板块流程高效",
        },
        {
          title: "理顺各部门和层级之间接口，避免管理真空",
        },
        {
          title: "减少管理制度使体系“瘦身",
        },
        {
          title: "建立标准化管理模式，可快速复制",
        },
        {
          title: "为业务数字化建立流程基础",
        },
      ],
      diamondLeft: [
        {
          title: "过程方法",
          imgUrl: require("../../../assets/images/ProcessOptimize13.png"),
        },
        {
          title: "职能分解",
          imgUrl: require("../../../assets/images/ProcessOptimize14.png"),
        },
        {
          title: "目标管理",
          imgUrl: require("../../../assets/images/ProcessOptimize15.png"),
        },
        {
          title: "管理四个层次",
          imgUrl: require("../../../assets/images/ProcessOptimize16.png"),
        },
      ],
      diamondRight: [
        {
          title: "流程优化",
          imgUrl: require("../../../assets/images/ProcessOptimize17.png"),
        },
        {
          title: "体系落地",
          imgUrl: require("../../../assets/images/ProcessOptimize18.png"),
        },
        {
          title: "体系检查",
          imgUrl: require("../../../assets/images/ProcessOptimize19.png"),
        },
        {
          title: "更多......",
          imgUrl: require("../../../assets/images/ProcessOptimize20.png"),
        },
      ],
      tabList: [
        "流程优化分析",
        "部门职能三级分解",
        "目标管理体系",
        "新型价值链案例",
        "系统文件梳理&体系",
      ],
      tabIndex: 0,
      tabImg: [
        require("../../../assets/images/ProcessOptimize22.png"),
        require("../../../assets/images/ProcessOptimize23.png"),
        require("../../../assets/images/ProcessOptimize24.png"),
        require("../../../assets/images/ProcessOptimize25.png"),
        require("../../../assets/images/ProcessOptimize26.png"),
      ],
    };
  },
  animateScroll,
  methods: {
    handleTab(index) {
      this.tabIndex = index;
    },
  },
};
</script>
  
  <style lang="less" scoped>
@import "../../../assets/style/Media.css";

.process-optimize {
  background: #ffffff;
  @media screen and (min-width: 1200px) and (max-width: 1519px) {
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .number1 {
      .des {
        line-height: 0.46rem !important;
      }
      .BoxTitle:nth-of-type(1) {
        p {
          padding-top: 0.5rem !important;
        }
      }
      .BoxTitle:nth-of-type(2) {
        margin-top: 0.4rem !important;
      }
      .ul {
        width: 100% !important;
        span {
          margin-top: 0.28rem !important;
          margin-right: 0.2rem !important;
        }
      }
    }
    .number2 {
      .box {
        & > div:nth-child(3) {
          width: 2rem !important;
        }
      }
    }
    .number4 {
      img {
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }
    .number5 {
      .contain {
        .ml36 {
          width: auto !important;
        }
        .meshing {
          display: block !important;
          text-align: center;
          img {
            max-width: 100% !important;
            width: 100% !important;
            height: auto !important;
          }
          .rightBox {
            .title {
              text-align: center;
            }
            .dec {
              margin: 0 auto;
              margin-top: 10px !important;
              text-align: left !important;
            }
            .btn {
              margin: 0 auto;
            }
          }
        }
      }
      .analyse {
        display: block !important;
        .leftBox {
          .img1 {
            top: 2.5rem !important;
            left: 0 !important;
          }
          .img2 {
            left: 0 !important;
          }
          .img4 {
            top: 4.5rem !important;
          }
        }
        .img3 {
          margin-top: 0.5rem !important;
          margin-left: 1.9rem !important;
        }
      }
    }
    .number7 {
      .tabBox {
        .tab {
          .tab-item {
            flex: 1;
            margin-right: 0.1rem;
            justify-content: space-between !important;
            .tabBtn {
              font-size: 12px !important;
              min-width: 0 !important;
              padding: 0 0.05rem !important;
            }
          }
          & > div:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
  }

  .number1 {
    position: relative;
    img {
      height: 8.4rem;
    }
    .contain {
      width: 80%;
      margin: 0 10%;
      position: absolute;
      top: 0;
      color: white;
      text-align: start;
      .BoxTitle:nth-of-type(1) {
        display: flex;
        justify-content: center;
        color: #ffffff;
        font-size: 0.4rem;
      }
      .BoxTitle:nth-of-type(2) {
        margin-top: 0.6rem;
        color: #ffffff;
        font-size: 0.4rem;
      }
      .des {
        padding-top: 0.3rem;
        font-weight: 400;
        font-size: 0.24rem;
        color: #ffffff;
        line-height: 0.48rem;
      }
      .ul {
        width: 95%;
        display: flex;
        justify-content: space-between;

        span {
          display: inline-block;
          // max-width: 2.7rem;
          height: 1rem;
          background: linear-gradient(94deg, #555974 0%, #5d616f 100%);
          border-radius: 0.1rem;
          border: 2px solid #74798a;
          text-align: center;
          font-weight: 400;
          font-size: 0.24rem;
          color: #e1e3ee;
          box-sizing: border-box;
          line-height: 1rem;
          margin-top: 0.3rem;
          cursor: default;
          flex: 1;
          margin-right: 0.26rem;
        }
        .dispalyNone {
          background: transparent;
          border: transparent;
        }
      }
    }
  }

  .number2 {
    position: relative;
    height: 8.4rem;
    background: url("../../../assets/images/ProcessOptimize1.png") no-repeat;
    background-size: 100% 100%;
    .BoxTitle {
      text-align: center;
      padding-top: 3%;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .box {
      .box-item {
        position: absolute;
        font-weight: 400;
        font-size: 0.24rem;
        text-shadow: 0px 1px 1px rgba(140, 172, 209, 0.59);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .hexagon {
          width: 0.545rem;
          height: 0.63rem;
          background-color: #4375c8;
          position: relative;
          clip-path: polygon(
            50% 0%,
            100% 25%,
            100% 75%,
            50% 100%,
            0% 75%,
            0% 25%
          );
          display: flex;
          justify-content: center;
          align-items: center;
          color: #e1e3ee;
        }
        p {
          margin-top: 0.1rem;
          color: #000000;
          line-height: 0.3rem;
          font-size: 0.24rem;
          font-weight: 400;
        }
      }
      & > div:nth-child(1) {
        width: 1.7rem;
        bottom: 44.25%;
        left: 12.14%;
      }
      & > div:nth-child(2) {
        width: 1.7rem;
        bottom: 47.86%;
        left: 27.24%;
      }
      & > div:nth-child(3) {
        width: 2.64rem;
        bottom: 52.86%;
        left: 40.78%;
      }
      & > div:nth-child(4) {
        width: 1.46rem;
        bottom: 45%;
        right: 34.17%;
      }
      & > div:nth-child(5) {
        width: 1.92rem;
        bottom: 31.67%;
        right: 21.09%;
      }
      & > div:nth-child(6) {
        width: 1.44rem;
        bottom: 40.12%;
        right: 9.58%;
      }
    }
  }

  .number3 {
    position: relative;
    height: 9.54rem;
    background: url("../../../assets/images/ProcessOptimize4.png") no-repeat;
    background-size: 100% 100%;
    .BoxTitle {
      text-align: center;
      padding-top: 3%;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    // .books {
    //     width: 100%;
    //     position: absolute;
    //     bottom: 9.54%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: end;
    //   & > img:nth-child(1) {
    //     width: 238px;
    //     height: 339px;
    //   }
    //   & > img:nth-child(2) {
    //     width: 286px;
    //     height: 406px;
    //   }
    //   & > img:nth-child(3) {
    //     width: 319px;
    //     height: 454px;
    //   }
    //   & > img:nth-child(4) {
    //     width: 285px;
    //     height: 406px;
    //   }
    //   & > img:nth-child(5) {
    //     width: 249px;
    //     height: 356px;
    //   }
    // }
  }

  .number4 {
    position: relative;
    height: 9.54rem;
    .BoxTitle {
      text-align: center;
      padding-top: 3%;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    img {
      position: absolute;
      width: 11.43rem;
      height: 5.46rem;
      bottom: 12.26%;
      left: 23.02%;
    }
  }

  .number5 {
    position: relative;
    background: #f7fafd;
    .BoxTitle {
      text-align: center;
      padding-top: 3%;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .contain {
      width: 80%;
      margin: 0 10%;
      margin-top: 0.54rem;
      p {
        font-weight: 400;
        font-size: 0.22rem;
        color: #333333;
        line-height: 0.36rem;
      }
      .ml36 {
        margin-left: 0.36rem;
        width: 12.54rem;
      }
      .title {
        font-weight: 400;
        font-size: 0.3rem;
        color: #000000;
        line-height: 0.36rem;
      }
      .meshing {
        display: flex;
        margin-top: 0.42rem;
        img {
          width: 7.66rem;
          height: 4.74rem;
        }
        .rightBox {
          margin-top: 0.44rem;
          margin-left: 0.55rem;
          .dec {
            margin-top: 0.66rem;
            width: 5.47rem;
          }
          .btn {
            margin-top: 0.7rem;
            width: 2.17rem;
            height: 0.72rem;
            background: #255ca2;
            border-radius: 0.16rem;
            font-weight: bold;
            font-size: 0.24rem;
            color: #ffffff;
            line-height: 0.72rem;
            text-shadow: 0px 1px 1px rgba(140, 172, 209, 0.59);
            text-align: center;
          }
        }
      }
      .analyse {
        position: relative;
        margin-top: 0.8rem;
        display: flex;
        .leftBox {
          .img1 {
            position: absolute;
            width: 0.85rem;
            height: 2.01rem;
            top: 1.2rem;
            left: 1.3rem;
          }
          .img2 {
            position: absolute;
            width: 1.87rem;
            height: 0.71rem;
            top: 0.8rem;
            left: 3.02rem;
          }
          .img4 {
            position: absolute;
            width: 8.92rem;
            height: 2.81rem;
            top: 3.9rem;
            left: 0;
          }
        }
        .img3 {
          width: 8.92rem;
          height: 3.35rem;
          margin-left: 0.7rem;
          margin-top: 0.4rem;
        }
      }
    }
    .bottomBox {
      height: 3.85rem;
      background: url("../../../assets/images/ProcessOptimize11.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .number6 {
    position: relative;
    height: 9.54rem;
    background: #f7fafd;
    .BoxTitle {
      text-align: center;
      padding-top: 3%;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .imgBox {
      position: absolute;
      bottom: 18.34%;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 4.16rem;
        height: 4.81rem;
      }
      .text {
        display: inline-block;
        width: 1.44rem;
        height: 1rem;
        font-weight: bold;
        font-size: 0.36rem;
        color: #ffffff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 38.36%;
      }
      .diamondLeft {
        & > div:nth-child(1) {
          position: absolute;
          top: -2.82%;
          left: -18.5%;
        }
        & > div:nth-child(2) {
          position: absolute;
          top: 25%;
          left: -40%;
        }
        & > div:nth-child(3) {
          position: absolute;
          top: 58.69%;
          left: -40%;
        }
        & > div:nth-child(4) {
          position: absolute;
          top: 85.21%;
          left: -14%;
        }
        .diamond-item {
          display: flex;
          position: relative;
          .lineBox {
            position: absolute;
            bottom: 0.2rem;
            left: -2.35rem;
            p {
              font-weight: 400;
              font-size: 0.3rem;
              color: #000000;
              line-height: 0.36rem;
              text-align: right;
              padding-right: 0.55rem;
            }
            .straight {
              display: inline-block;
              width: 2.5rem;
              border: 1px solid #255ca2;
            }
            .broken {
              display: inline-block;
              width: 0.3rem;
              border: 1px solid #255ca2;
              transform: rotate(32deg);
              transform-origin: 0 0;
            }
          }
          img {
            width: 1.15rem;
            height: 1.26rem;
          }
        }
      }
      .diamondRight {
        & > div:nth-child(1) {
          position: absolute;
          top: -2.82%;
          right: -18.5%;
        }
        & > div:nth-child(2) {
          position: absolute;
          top: 25%;
          right: -40%;
        }
        & > div:nth-child(3) {
          position: absolute;
          top: 58.69%;
          right: -40%;
        }
        & > div:nth-child(4) {
          position: absolute;
          top: 85.21%;
          right: -14%;
        }
        .diamond-item {
          display: flex;
          position: relative;
          .lineBox {
            position: absolute;
            bottom: 0.2rem;
            right: -2.35rem;
            p {
              font-weight: 400;
              font-size: 0.3rem;
              color: #000000;
              line-height: 0.36rem;
              padding-left: 0.55rem;
            }
            .straight {
              display: inline-block;
              width: 0.3rem;
              border: 1px solid #255ca2;
              transform: rotate(-32deg);
              transform-origin: right 0;
            }
            .broken {
              display: inline-block;
              width: 2.5rem;
              border: 1px solid #255ca2;
            }
          }
          img {
            width: 1.15rem;
            height: 1.26rem;
          }
        }
      }
    }
  }

  .number7 {
    position: relative;
    min-height: 9.54rem;
    .BoxTitle {
      text-align: center;
      padding-top: 3%;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .tabBox {
      width: 80%;
      margin: 0 auto;
      margin-top: 0.85rem;
      text-align: center;
      .tab {
        display: flex;
        justify-content: space-around;
        .tab-item {
          position: relative;
          .tabBtn {
            min-width: 2.68rem;
            background: #ffffff;
            border-radius: 0.4rem;
            border: 1px solid #e5e6e7;
            height: 0.78rem;
            line-height: 0.78rem;
            font-weight: 400;
            font-size: 0.3rem;
            color: #000000;
            text-align: center;
            box-sizing: border-box;
            padding: 0 0.14rem;
            cursor: pointer;
          }
          img {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -0.15rem;
            width: 2.14rem;
            height: 0.08rem;
          }
          .active {
            font-weight: bold;
            color: #ffffff;
            background: #4375c8;
          }
        }
      }
      .lineBox {
        width: 90%;
        height: 2px;
        background: #f5f5f5;
        margin: 0 auto;
        margin-top: 0.1rem;
      }
      .tabContent {
        margin-top: 0.5rem;
        // .img0 {
        //   width: 10.22rem;
        //   height: 5.5rem;
        // }
        // .img1 {
        //   width: 8.84rem;
        //   height: 6.03rem;
        // }
        // .img2 {
        //   width: 9.43rem;
        //   height: 6.04rem;
        // }
        // .img3 {
        //   width: 15.53rem;
        //   height: 5.23rem;
        // }
        // .img4 {
        //   width: 11.31rem;
        //   height: 5.14rem;
        // }
        img {
          max-width: 100%;
          width: auto 0;
        }
      }
    }
  }
}
</style>